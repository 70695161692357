<mat-form-field appearance="outline" class="w-full" (click)="datepicker.open()">
	<mat-label>{{ label | translate }}</mat-label>
	<input
		[dir]="translate.currentLang == 'en' ? 'ltr' : 'rtl'"
		matInput
		[matDatepicker]="datepicker"
		[formControl]="date"
		(dateChange)="dateValue.emit(date.value)"
		[readonly]="true"
	/>
	<button
		matSuffix
		mat-icon-button
		aria-label="Clear"
		type="reset"
		(click)="date.setValue(''); dateValue.emit(date.value)"
		*ngIf="date?.value"
		class="w-6 h-6 rounded-md text-dark-grey"
	>
		<img
			src="./assets/images/close-square.svg"
			class="text-xs font-bold mb-0.5 inline-block align-text-top"
		/>
	</button>
	<mat-datepicker #datepicker> </mat-datepicker>
</mat-form-field>
<!-- 
<mat-form-field appearance="outline">
	<mat-label>Enter a date range</mat-label>
	<mat-date-range-input [rangePicker]="rangePicker">
		<input matStartDate placeholder="Start date" />
		<input matEndDate placeholder="End date" />
	</mat-date-range-input>
	<mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
	<mat-date-range-picker #rangePicker>
		<mat-date-range-picker-actions>
			<button class="text-medium-gray" mat-button matDateRangePickerCancel>
				Cancel
			</button>
			<button class="ok" mat-button matDateRangePickerApply>Ok</button>
		</mat-date-range-picker-actions>
	</mat-date-range-picker>
</mat-form-field> -->
