<div class="mt-5 search flex items-center justify-start">
	<div
		*ngIf="showSearch"
		appearance="outline"
		class="mr-12px rtl:ml-12px w-full relative flex items-center border  rounded-[{{
			isRounded ? 50 : 6
		}}px] py-[13px] px-[12px] bg-white"
	>
		<!-- *ngIf="!searchVal.value" -->

		<img
			class="absolute rtl:right-[14px] ltr:left-[14px]"
			src="./assets/images/search-normal.svg"
			alt=""
			srcset=""
		/>
		<input
			[formControl]="searchVal"
			class="bg-transparent px-[40px] text-dark-grey"
			matInput
			placeholder="{{ searchKey | translate }}"
			(keyup.enter)="keyWord.emit(searchVal.value)"
			(keyup)="inputChange($event)"
		/>

		<div class="flex absolute ltr:right-0 rtl:left-0" *ngIf="searchVal.value">
			<img
				class="inline-block cursor-pointer"
				src="./assets/images/close-square.svg"
				alt=""
				(click)="searchVal.setValue(''); keyWord.emit(searchVal.value)"
			/>
			<div
				class="h-[19px] w-[1px] border-r-[1px] border-secondry-color-Gray inline-block mx-12px max-mobile:mx-1"
			></div>
			<img
				(click)="keyWord.emit(searchVal.value)"
				class="rtl:ml-3 ltr:mr-3 inline-block cursor-pointer"
				src="./assets/images/search-gradient.svg"
				alt=""
			/>
		</div>
	</div>
	<button
		class="bg-gradient inline-flex rounded-[12px] items-center box-shadow"
		*ngIf="showFilter"
		(click)="toggleFilter.emit()"
	>
		<img class="fill-[#FCFCFC] p-12px" src="assets/images/filter.svg" alt="" />
		<div
			*ngIf="filterCount"
			class="text-white inline-flex items-center ltr:pr-6 rtl:pl-6"
		>
			<span class="whitespace-nowrap">{{
				'ACTIONS.APPLY_NO_FILTER' | translate : { number: filterCount }
			}}</span>
			<button
				matSuffix
				mat-icon-button
				aria-label="Clear"
				type="reset"
				(click)="resetFilter.emit()"
				class="w-6 h-6 border-2 mx-4 border-solid rounded-md border-dark-white flex items-center justify-center"
			>
				<mat-icon class="text-xs font-bold leading-[21px]">close</mat-icon>
			</button>
		</div>
	</button>
</div>
