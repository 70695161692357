<div
	class="flex flex-wrap {{
		smallSize ? 'flex-col content-baseline items-start' : ''
	}}"
>
	<div class="flex text-[#6E7191] mt-2 lg:w-[40%] md:w-[35%] w-[100%]">
		<p class="mt-2 white-space-nowrap">
			{{
				'GENERAL.PAGINATION'
					| translate : { from: from, to: to, total: totalCount }
			}}
		</p>
		<div
			class="px-2 flex border-secondry-color-Gray border-2 rounded-[6px] mx-2 h-[32px] w-[50px]"
		>
			<mat-select
				value="10"
				class="mt-1.5 text-[#4E4B66] text-[12px]"
				[formControl]="pageSize"
				[value]="+pageCount"
				(selectionChange)="
					itemsCountPerPage.emit($event.source.value); paginationChanged()
				"
			>
				<mat-option [value]="10">10</mat-option>
				<mat-option [value]="25">25</mat-option>
				<mat-option [value]="50">50</mat-option>
				<mat-option [value]="100">100</mat-option>
				<mat-option [value]="200">200</mat-option>
			</mat-select>
		</div>
	</div>

	<pagination-controls
		*ngIf="totalCount > pageSize?.value"
		(pageChange)="
			currentPage.emit($event); pageNumber = $event; paginationChanged()
		"
		nextLabel=""
		previousLabel=""
	></pagination-controls>
</div>
