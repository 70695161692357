import { Component, Input, OnInit } from '@angular/core'
import { OwlOptions } from 'ngx-owl-carousel-o'
import { environment } from '../../../../environments/environment'

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @Input() imagesList: { src: string }[] = []
  @Input() useImagePath : boolean = false
  customOptions: OwlOptions = {
    loop: false,
    touchDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    items: 1
  }
  imagePath = environment.attachmentPath
  constructor() { }

  ngOnInit(): void {
  }

}
