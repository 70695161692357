<image-cropper
	[imageChangedEvent]="data.imageChangedEvent"
	[maintainAspectRatio]="true"
	[aspectRatio]="data.imageUpload.imageCropDimensions"
	format="png"
	(imageCropped)="imageCropped($event)"
	[resizeToWidth]="data.imageUpload.maxWidth ? data.imageUpload.maxWidth : 500"
></image-cropper>
<div class="w-[88.5%] m-auto">
	<button
		[mat-dialog-close]="croppedImage"
		cdkFocusInitial
		class="w-100 bg-gradient text-white w-full rounded-[6px] py-[10px]"
	>
		{{ 'ACTIONS.CROP' | translate }}
	</button>
</div>
