import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
	// constructor(private injector: Injector) {}
	transform(value: Date, currentLang: string): any {
		const time = new Date(value)
		const now = new Date()
		const formattedDate = time.toLocaleDateString('en-US', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		})
		// Time intervals in milliseconds

		const timeDifference = Math.floor((+new Date() - +new Date(value)) / 1000)
		const minute = 60
		const hour = 3600
		const day = 86400
		const month = 2592000
		const year = 31536000
		const nowValue = currentLang === 'ar' ? ' الآن' : ' Just now'
		const agoValue = currentLang === 'ar' ? ' منذ' : ' ago'

		if (timeDifference < 29) {
			// less than 30 seconds ago will show as 'Just now'
			return currentLang === 'ar' ? ' الآن' : ' Just now'
		} else if (timeDifference < hour) {
			const minuteValue = currentLang === 'ar' ? ' دقيقة' : ' minute'
			const minutsValue = currentLang === 'ar' ? ' دقائق' : ' minutes'
			const minutes = Math.floor(timeDifference / minute)
			return `${currentLang === 'ar' ? agoValue : ''} ${minutes} ${
				minutes > 1 ? minutsValue : minuteValue
			} ${currentLang === 'en' ? agoValue : ''}`
		} else if (timeDifference < day) {
			const hourValue = currentLang === 'ar' ? ' ساعة' : ' hour'
			const hoursValue = currentLang === 'ar' ? ' ساعات' : ' hours'
			const hours = Math.floor(timeDifference / hour)
			return `${currentLang === 'ar' ? agoValue : ''} ${hours} ${
				hours > 1 ? hoursValue : hourValue
			} ${currentLang === 'en' ? agoValue : ''}`
		} else if (timeDifference < month) {
			const dayValue = currentLang === 'ar' ? ' امس' : ' yesterday'
			const daysValue = currentLang === 'ar' ? ' ايام' : ' days'
			const days = Math.floor(timeDifference / day)
			return ` ${
				days > 1
					? formattedDate
					: `${currentLang === 'en' ? 'yesterday' : 'امس'}`
			}`
		} else {
			return formattedDate
		}
	}
}
