<div *ngIf="editMode" [formGroup]="workingHoursForm">
	<mat-accordion formArrayName="workingHours">
		<mat-expansion-panel
			*ngFor="let form of getFormArray(); let i = index"
			[formGroup]="form"
		>
			<mat-expansion-panel-header>
				<mat-panel-title class="flex-grow">
					<div class="lg:w-[6%] m-auto mx-2">
						<mat-slide-toggle
							(toggleChange)="toggleChanged(form, i)"
							formControlName="isOn"
							>Slide me!</mat-slide-toggle
						>
					</div>
					<h1 class="text-medium-gray text-font-16 leading-[24px] mb-[6px]">
						{{
							'DAYS.' +
								weekDays[form?.get('dayOfWeek')?.value].name.toUpperCase()
								| translate
						}}
					</h1>
				</mat-panel-title>
				<!-- <mat-panel-description>
					Currently I am {{ panelOpenState ? 'open' : 'closed' }}
				</mat-panel-description> -->
			</mat-expansion-panel-header>
			<div class="flex mb-24px justify-between">
				<div
					class="flex flex-row-reverse justify-end border-secondry-color-Gray border-[1px] rounded-6px py-12px lg:px-12px ltr:mr-12px rtl:ml-12px w-full"
				>
					<input
						[format]="12"
						[max]="getMin(form?.get('to')?.value)"
						[ngClass]="!editMode ? 'text-[#6E7191]' : 'text-[#4E4B66]'"
						[ngxTimepicker]="toggleTimepickerFrom"
						class="focus:outline-none bg-off-white text-font-16 text-dark-grey w-2/3"
						formControlName="from"
						readonly
					/>
					<ngx-material-timepicker-toggle
						[for]="toggleTimepickerFrom"
						[ngClass]="!editMode ? 'fill-[#6E7191]' : 'fill-[#4E4B66]'"
					></ngx-material-timepicker-toggle>
					<ngx-material-timepicker
						#toggleTimepickerFrom
						(closed)="validateWorkingHoures(form, i)"
					></ngx-material-timepicker>
				</div>
				<div
					class="toggle-example flex flex-row-reverse justify-end border-secondry-color-Gray border-[1px] rounded-6px py-12px lg:px-12px w-full"
				>
					<input
						[format]="12"
						[min]="getMin(form?.get('from')?.value)"
						[ngClass]="!editMode ? 'text-[#6E7191]' : 'text-[#4E4B66]'"
						[ngxTimepicker]="toggleTimepickerTo"
						class="focus:outline-none bg-off-white text-font-16 text-dark-grey w-2/3"
						formControlName="to"
						readonly
					/>

					<ngx-material-timepicker-toggle
						[for]="toggleTimepickerTo"
						[ngClass]="!editMode ? 'fill-[#6E7191]' : 'fill-[#4E4B66]'"
					></ngx-material-timepicker-toggle>
					<ngx-material-timepicker
						#toggleTimepickerTo
						(closed)="validateWorkingHoures(form, i)"
					></ngx-material-timepicker>
				</div>
			</div>
			<div *ngIf="showOffHours[i]" class="flex mb-24px justify-between">
				<div
					class="flex flex-row-reverse justify-end border-secondry-color-Gray border-[1px] rounded-6px py-12px lg:px-12px ltr:mr-12px rtl:ml-12px w-full"
				>
					<input
						[format]="12"
						[max]="getMin(form?.get('offTimeTo')?.value)"
						[ngClass]="!editMode ? 'text-[#6E7191]' : 'text-[#4E4B66]'"
						[ngxTimepicker]="toggleTimepickerOffFrom"
						class="focus:outline-none bg-off-white text-font-16 text-dark-grey w-2/3"
						formControlName="offTimeFrom"
						readonly
					/>
					<ngx-material-timepicker-toggle
						[for]="toggleTimepickerOffFrom"
						[ngClass]="!editMode ? 'fill-[#6E7191]' : 'fill-[#4E4B66]'"
					></ngx-material-timepicker-toggle>
					<ngx-material-timepicker
						#toggleTimepickerOffFrom
						(closed)="validateWorkingHoures(form, i)"
					></ngx-material-timepicker>
				</div>
				<div
					class="toggle-example flex flex-row-reverse justify-end border-secondry-color-Gray border-[1px] rounded-6px py-12px lg:px-12px w-full"
				>
					<input
						[format]="12"
						[min]="getMin(form?.get('offTimeFrom')?.value)"
						[ngClass]="!editMode ? 'text-[#6E7191]' : 'text-[#4E4B66]'"
						[ngxTimepicker]="toggleTimepickerOffTo"
						class="focus:outline-none bg-off-white text-font-16 text-dark-grey w-2/3"
						formControlName="offTimeTo"
						readonly
					/>

					<ngx-material-timepicker-toggle
						[for]="toggleTimepickerOffTo"
						[ngClass]="!editMode ? 'fill-[#6E7191]' : 'fill-[#4E4B66]'"
					></ngx-material-timepicker-toggle>
					<ngx-material-timepicker
						#toggleTimepickerOffTo
						(closed)="validateWorkingHoures(form, i)"
					></ngx-material-timepicker>
				</div>
			</div>
			<p
				*ngIf="showError[i]"
				class="{{ showValidationError[i] ? 'text-red-error' : '' }}"
			>
				{{ showError[i] }}
			</p>

			<div
				(click)="showOffHours[i] = true"
				*ngIf="!showOffHours[i]"
				class="flex justify-end"
			>
				<button class="bg-gradient text-off-white rounded" mat-button>
					+ {{ 'ACTIONS.ADD_BREAK_TIME' | translate }}
				</button>
			</div>
			<div
				(click)="deleteOffTime(form, i)"
				*ngIf="showOffHours[i]"
				class="flex justify-end"
			>
				<button class="bg-gradient text-off-white rounded" mat-button>
					+ {{ 'ACTIONS.DELETE_BREAK_TIME' | translate }}
				</button>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</div>
<!-- <div [formGroup]="workingHoursForm" *ngIf="editMode">
	<div formArrayName="workingHours">
		<div *ngFor="let form of getFormArray(); let i = index" [formGroup]="form">
			<ng-container>
				<h1
					class="text-medium-gray text-font-16  leading-[24px] mb-[6px]"
				>
					{{
						'DAYS.' + weekDays[form?.get('dayOfWeek')?.value].name.toUpperCase()
							| translate
					}}
				</h1>
				<div class="flex mb-24px justify-between">
					<div class="lg:w-[6%] m-auto mx-2">
						<mat-slide-toggle
							formControlName="isOn"
							(toggleChange)="toggleChanged(form, i)"
							>Slide me!</mat-slide-toggle
						>
					</div>
					<div
						class="flex flex-row-reverse justify-end border-secondry-color-Gray border-[1px] rounded-6px py-12px lg:px-12px ltr:mr-12px rtl:ml-12px w-full"
					>
						<input
							class="focus:outline-none bg-off-white text-font-16  text-dark-grey w-2/3"
							[ngxTimepicker]="toggleTimepickerFrom"
							readonly
							[format]="12"
							formControlName="from"
							[max]="getMin(form?.get('to')?.value)"
							[ngClass]="!editMode ? 'text-[#6E7191]' : 'text-[#4E4B66]'"
						/>
						<ngx-material-timepicker-toggle
							[for]="toggleTimepickerFrom"
							[ngClass]="!editMode ? 'fill-[#6E7191]' : 'fill-[#4E4B66]'"
						></ngx-material-timepicker-toggle>
						<ngx-material-timepicker
							#toggleTimepickerFrom
						></ngx-material-timepicker>
					</div>
					<div
						class="toggle-example flex flex-row-reverse justify-end border-secondry-color-Gray border-[1px] rounded-6px py-12px lg:px-12px w-full"
					>
						<input
							class="focus:outline-none bg-off-white text-font-16  text-dark-grey w-2/3"
							[ngxTimepicker]="toggleTimepickerTo"
							[format]="12"
							readonly
							formControlName="to"
							[min]="getMin(form?.get('from')?.value)"
							[ngClass]="!editMode ? 'text-[#6E7191]' : 'text-[#4E4B66]'"
						/>

						<ngx-material-timepicker-toggle
							[for]="toggleTimepickerTo"
							[ngClass]="!editMode ? 'fill-[#6E7191]' : 'fill-[#4E4B66]'"
						></ngx-material-timepicker-toggle>
						<ngx-material-timepicker
							#toggleTimepickerTo
						></ngx-material-timepicker>
					</div>
				</div>
			</ng-container>
			<p *ngIf="showError[i]">{{ showError[i] }}</p>
		</div>
	</div>
</div> -->
<app-working-hours-view-mode
	*ngIf="!editMode"
	[workingHours]="workingHours"
></app-working-hours-view-mode>
