import { ChangeDetectorRef, Component } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { LoadingService } from './shared/services/loading.service'
import { SharedService } from './shared/services/shared.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showloading = false

  constructor(private translate: TranslateService, private loadingServ: LoadingService, private cd: ChangeDetectorRef,
    private sharedServ: SharedService) {

    const lang = localStorage.getItem('language')
    this.translate.use(lang ? lang : 'en')
    document.dir = lang && lang == 'ar' ? 'rtl' : 'ltr';
    localStorage.getItem('breadCrumbRout') ? sharedServ.breadCrumbRout.next(JSON.parse(localStorage.getItem('breadCrumbRout') || '')) : null
    this.sharedServ.breadCrumbRout.subscribe((res:any) => localStorage.setItem('breadCrumbRout',JSON.stringify(res)))
  }

  ngAfterViewInit(): void {
    this.loadingServ.showLoading.subscribe(
      res => {
        this.showloading = res; this.cd.detectChanges()
      }
    )
  }

  ngOnDestroy() {
  }

}
