import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
	constructor() {}
	@Input() smallSize: boolean = false
	@Input() totalCount: number = 0
	@Input() pageNumber: number = 0
	@Input() pageCount: number = 10
	@Output() currentPage: EventEmitter<number> = new EventEmitter()
	@Output() itemsCountPerPage: EventEmitter<number> = new EventEmitter()
	pageSize: FormControl = new FormControl(10)
	from = this.totalCount ? 1 : 0
	to = 10
	ngOnInit(): void {}

	ngOnChanges() {
		this.from = this.totalCount ? 1 : 0
		this.to = this.totalCount > 10 ? 10 : this.totalCount
		this.pageSize.setValue(+this.pageCount)
		this.paginationChanged()
	}

	paginationChanged() {
		this.from =
			this.pageNumber > 1
				? (this.pageNumber - 1) * this.pageSize?.value + 1
				: this.totalCount > 1
				? 1
				: 0
		this.to =
			this.from + this.pageSize?.value > this.totalCount
				? this.totalCount
				: this.from + (this.pageSize?.value - 1)
	}
}
