import { Component, Input, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Languages } from '../../constants/countries.constant'

@Component({
	selector: 'app-language',
	templateUrl: './language.component.html',
})

export class LanguageComponent implements OnInit {
	@Input() isHeader = false
	Languages = Languages
	lang : FormControl = new FormControl(localStorage.getItem('language') || 'en')

	constructor(
	) {}

	ngOnInit(): void {
	}

	langChanges() {
		localStorage.setItem('language', this.lang?.value)
		window.location.reload();
	}
}
