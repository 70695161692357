import { Component, Input, OnChanges, OnInit } from '@angular/core'

@Component({
	selector: 'app-status',
	templateUrl: './status.component.html',
	styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit, OnChanges {
	@Input() enumData: { name: string | any; value: string }[] = []
	@Input() statusValue: any = ''
	@Input() ExtraText: any = ''
	redStatus = [
		'INACTIVE',
		'REJECTED',
		'CLOSED',
		'BLOCKED',
		'CANCELLED',
		'UNPAID',
		'CLOSED',
	]
	greenStatus = [
		'ACTIVE',
		'ACCEPTED',
		'COMPLETED',
		'COMPLETE',
		'CONFIRMED',
		'PAID',
		'PAYATSTORE',
		'OPEN',
		'REFUNDED',
		'FAWRY',
	]
	yellowStatus = ['PENDING', 'UNCONFIRMED', 'PAYONLINE', 'NEW', 'BANKACCOUNT']
	salonStatus = ['DIDNOTSHOWUP', 'INSTAPAY']

	statusName = ''
	constructor() {}

	ngOnInit(): void {}
	ngOnChanges() {
		this.getStatusName()
	}

	getStatusName() {
		this.statusName = this.enumData
			?.find((obj) => obj.value == this.statusValue)
			?.name.toUpperCase()
	}
}
