import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { SharedService } from 'src/app/shared/services/shared.service'
import { environment } from 'src/environments/environment'
import { Pattern } from '../../constants/patterns.constant'
import { Countries, MobileNumber } from '../../models/general.models'

@Component({
  selector: 'app-mobile-number',
  templateUrl: './mobile-number.component.html',
  styleUrls: ['./mobile-number.component.scss'],
})
export class MobileNumberComponent implements OnInit {
  @Input() mobileNumbers: MobileNumber[] | undefined
  @Input() validateNumbers: any
  @Input() isEditMode: boolean = true
  @Input() required: boolean = false
  @Input() country: Countries | any
  @Output() emitMobiles = new EventEmitter()

  mobilesForm = new FormGroup({
    mobileNumbers: new FormArray([], this.required ? Validators.required : []),
  })
  subscription = new Subscription()
  // countriesCodes: Country[] = []
  attachmentUrl = environment.attachmentPath
  isDuplicated = false

  constructor(
    private sharedServ: SharedService,
    public translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.initForm()
  }

  initForm() {
    if (this.mobileNumbers?.length) {
      this.getFormArray().clear()
      this.mobileNumbers.forEach((mobile, index) => {
        this.getFormArray().push(
          new FormGroup({
            mobileNumber: new FormControl(
              mobile?.mobileNumber || '',
              this.checkPhoneValidator(),
            ),
          }),
        )
      })
    } else {
      this.getFormArray().clear()
      this.addNewMobile()
    }
    this.emitMobiles.emit({
      mobiles: this.handleBeforEmit(),
      isValid: this.mobilesForm.valid && !this.isDuplicated,
    })

    this.subscription.add(
      this.mobilesForm.valueChanges.subscribe((val) => {
        this.isDuplicated = false
        let mobiles: any = val?.mobileNumbers?.map((num: any) =>
          num?.mobileNumber.toString(),
        )
        mobiles.forEach((mobile: string) => {
          if (mobile?.trim()?.length) {
            let duplicated = mobiles.filter((val: string) => val == mobile)
            if (duplicated?.length > 1) {
              this.isDuplicated = true
              return
            }
          }

        })
        this.emitMobiles.emit({
          mobiles: this.handleBeforEmit(),
          isValid: this.mobilesForm.valid && !this.isDuplicated,
        })

      }),
    )
  }

  getMobilePlaceHolder(i: number) {
    const placeholders: any = {
      0: 'GENERAL_LABELS.MOBILE_NUMBER',
      1: 'GENERAL_LABELS.SECOND_MOBILE_NUMBER',
      2: 'GENERAL_LABELS.THIRD_MOBILE_NUMBER',
    }
    return placeholders[i]
  }

  handleBeforEmit() {
    if (this.getFormArray()?.length && this.getFormArray().controls[0]?.get('mobileNumber')?.value) {
      let mobiles: any[] = []
      this.getFormArray().controls.map((control) => {
        if ((control as FormGroup)?.get('mobileNumber')?.value) {
          mobiles.push({
            phoneCode: this.country?.phoneCode,
            phoneCountryId: this.country?.id,
            countryCode: this.country?.countryCode,
            mobileNumber: (control as FormGroup)?.get('mobileNumber')?.value,
          })
        }
      })
      return mobiles

    } else return []

  }

  addNewMobile() {
    if (this.getFormArray().length < 3) {
      this.getFormArray().push(
        new FormGroup({
          mobileNumber: new FormControl('', this.checkPhoneValidator()),
          // country: new FormControl(this.country, Validators.required),
        }),
      )
    }
  }

  removeMobile(index: number) {
    this.getFormArray().removeAt(index)
  }

  checkPhoneValidator() {
    let validators = []
    this.required || this.getFormArray().length > 1 ? validators.push(Validators.required) : null
    this.country?.id == 224 ? validators.push(Validators.pattern(Pattern.phoneEgyptWithoutCode)) :
      validators.push(Validators.pattern(Pattern.phoneSaudiArabiaWithoutCode))
    return validators
  }

  getFormArray() {
    return this.mobilesForm.get('mobileNumbers') as FormArray
  }

  getController(index: number, controllerName: string) {
    return (this.getFormArray()?.controls[index] as FormGroup)?.get(
      controllerName,
    )
  }

  compareWith(a: any, b: any) {
    return a?.id == b?.id
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
