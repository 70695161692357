import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'customDuration' })
export class CustomDurationPipe implements PipeTransform {
	transform(value: string, currentLang: string) {
		const fullTimeArr = value?.split(':')
		const hoursText =
			fullTimeArr[0] !== '00'
				? this.getHoursFullText(fullTimeArr[0], currentLang)
				: ''
		const minusValue = currentLang === 'ar' ? ' دقيقه' : ' mins'
		const minusText =
			fullTimeArr[1] !== '00' ? `${fullTimeArr[1]}${minusValue}` : ''

		return `${hoursText} ${minusText}`
	}

	getHoursFullText(hourValue: string, currentLang: string) {
		const hourText = currentLang === 'ar' ? ' ساعه' : ' Hour'
		const hoursText = currentLang === 'ar' ? ' ساعات' : ' Hours'
		if (hourValue === '01') return `1 ${hourText}`
		else if (hourValue === '10') return `10 ${hourText}`
		return hourValue.replace('0', '') + hoursText
	}
}
