import { Component, Input, OnInit } from '@angular/core'
import { workingHour } from '../../models/general.models'
import { weekDays } from '../../enums/branch-types.enum'
import { TranslateService } from '@ngx-translate/core'
import { WorkingHoursStatusEnum } from '../../enums/working-hours-enum'
import { SharedService } from '../../services/shared.service'

@Component({
	selector: 'app-working-hours-view-mode',
	templateUrl: './working-hours-view-mode.component.html',
	styleUrls: ['./working-hours-view-mode.component.scss'],
})
export class WorkingHoursViewModeComponent implements OnInit {
	@Input() workingHours: workingHour[] = []
	@Input() editMode: boolean = false
	weekDays = weekDays

	WorkingHoursStatus =
		this.sharedService.returnEnumArray(WorkingHoursStatusEnum) || []
	constructor(
		public translate: TranslateService,
		private sharedService: SharedService
	) {}

	ngOnInit(): void {
		// this.handel24To12()
	}
	ngOnChanges(): void {
		this.handel24To12()
	}
	handel24To12() {
		if (this.workingHours?.length) {
			this.workingHours.forEach((time) => {
				time.from = this.handelTime(time.from)
				time.to = this.handelTime(time.to)
			})
		}
	}
	handelTime(time: any) {
		let newTime = new Date()
		newTime.setHours(
			time.split(':')[0] > 12 ? time.split(':')[0] - 12 : time.split(':')[0] == "00" ? 12 : time.split(':')[0]
		)
		newTime.setMinutes(time.split(':')[1]?.split(' ')[0])
		return (
			newTime.toTimeString().split(' ')[0].split(':')[0] +
			':' +
			newTime.toTimeString().split(' ')[0].split(':')[1] +
			(time.split(':')[0] >= 12
				? this.translate.currentLang == 'en'
					? 'PM'
					: 'م'
				: this.translate.currentLang == 'en'
				? 'AM'
				: 'ص')
		)
	}
}
