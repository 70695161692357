<form class="max-w-screen-md m-auto bg-white p-3 rounded-lg overflow-y">
	<div class="ltr:text-right rtl:text-left">
		<button
			class="text-gray-700 text-md border-2 border-solid rounded-md border-[#6E7191] w-[21.5px] h-[21.6px]"
		>
			<mat-icon
				(click)="closeDialog()"
				class="text-font-12 w-full h-auto leading-3 font-bold"
				>close</mat-icon
			>
		</button>
	</div>

	<div class="flex rtl:flex-row-reverse justify-between align-middle mt-4">
		<p
			class="m-0 text-gray-600 ltr:font-Poppins-semi-bold rtl:font-semibold text-font-24"
		>
			{{ 'AUTH.RESET_PASSWORD.TERMS' | translate }}
		</p>
	</div>
	<div *ngIf="lang === 'en'" class="mt-4 text-left">
		<p class="text-gray-400">
			By using this platform, through the mobile application or the web, you as
			a client are bound by and should comply with the provisions of the user
			agreement for Go Care. Usage of your personal data any personal data you
			share with Go Care will be only used to improve the user experience. Your
			personal data will be shared with our marketing partners. Providers,
			services and price list the purpose of this app is to provide a simple and
			convenient service to consumers, linking them to beauty centers and
			clinics in their area. The beauty centers and clinics, Go Care work with,
			are obligated to comply with country local laws, rules, regulations, and
			standards of beauty industry, sale, marketing and safety. Go Care does not
			in any way independently verify the credentials, representations or
			products of beauty centers and clinics, or the quality of any products. It
			is the client’s responsibility to be comfortable with the information
			provided by beauty centers and clinics on the applications. by information
			we mean: providers information, service delivery, and information about
			products, services, offers, packages, etc. Go Care have no liability or
			responsibility for that information. And definitely Go Care seeks to add
			more credible beauty salons and clinics. All service preparation is the
			sole responsibility of the beauty center or clinic accepting the order.
			Clients, off course, reserve the right to report a provider or submit a
			complaint or rate the provider. Go Care is responsible for the price lists
			enlisted on the application. However, the list is frequently updated.
			Clients have to make sure that they check the prices before heading to the
			service provider. At-home services Go Care offers you at-home services.
			Please be informed that some services cannot be served at-home. Home
			services follows strict rules to ensure the comfort of our valued
			customers as well as to guarantee the rights of our service providers as
			described below. Booking this service requires paying a deposit, otherwise
			the booking will be considered cancelled. Booking may require fawry or
			visa payments (if applicable). After booking, wait for a phone call from
			Go Care customer service agents or service provider to confirm and inform
			you of payment details. Refunding complies to the terms and conditions of
			each service. Also, it complies to the terms and conditions of payment
			methods. The user must clarify in advance any requirements regarding
			materials or any other clarifications in the notes field. The user is
			obligated to give the correct personal information and the correct
			address. The aim of providing this service is to help clients therefore it
			is inappropriate to cause harm or harassment to the service provider. The
			aim of providing this service is to help clients therefore it is
			inappropriate to cause harm or harassment to the service provider. If you
			want to add another service while booking, please use the app to guarantee
			the price and Quality. Prices shown on the application are approximate and
			may change according to the terms and conditions of the service. The
			service provider has the right to accept or refuse the booking because of
			the address and distance, or due to busyness or any other reasons. The
			service provider has the right to change the materials used in accordance
			with the required service. Maintaining your personal belongings is your
			sole responsibility. Bookings cannot be canceled one day before the
			service is due or during its provision. This may expose you to
			compensatory fees. This is a beta version of consultation services. we are
			exerting efforts to update this service to better serve the needs of our
			esteemed users. We also note the following: This service is completely
			free of charge for a limited time. The data you fill in the consultation
			form is used for promotional purposes and to help us improve the service.
			Only beauty, cosmetic and health experts are responsible for the advices
			given. Go Care reserves the right to alter these terms and conditions
			without notice. No show, booking and cancellation if a client doesn’t
			arrive on their booked time, the provider has the right to cancel their
			booking. If the provider cancels the booking without reason, Go Care is
			responsible for this problem. We urge our clients to report that. Offers,
			packages and payments each offer or package is guided by terms and
			conditions. The primary payment method is cash. some services and offers
			may require electronic or credit payment by visa card or mastercard. Fawry
			payments and refunds there are several methods of payment for the service
			provided through the application. Those are: cash payment, payment through
			the electronic wallet, payment by credit or visa card, and payment through
			fawry.
			<br />
			<br />
			• In case of paying through fawry, please Keep your fawry receipt as
			payment proof. <br />
			<br />
			• Your payments can be refunded according to the general terms &
			conditions of refunds and according to the terms & conditions of the
			service, as well. <br />
			<br />
			• Availability of refunding depends mainly on the booking date. In some
			cases, refunds are not available. <br />
			<br />
			• Refunds applies to the following cases: booking by mistake – provider
			didn’t confirm booking – provider cancelled the booking – service is not
			available – circumstances of the client. whereas the terms and conditions
			of payment & refund are not violated or contradicted. <br />
			<br />
			• Fawry payments can be refunded only in two formats: money to your visa
			card or in-app wallet money. note that: <br />
			<br />
			• Refunding to your visa card is delivered within 14-days. <br />
			<br />
			• Refunding as in-app wallet money is delivered within 24 hours. <br />
			<br />
			• In-app wallet money can be only used to book services and cannot be
			converted to real cash amount. Visa payments and refunds <br />
			<br />
			• Paying through visa may be available for some service providers. <br />
			<br />
			• Booking may require other payment methods for some services. <br />
			<br />
			• The customer is committed to ensuring that the card balance is
			sufficient to complete the payment. <br />
			<br />
			• The customer must Keep the payment receipt as a payment proof. <br />
			<br />
			• Payment by visa is subject to the legal rules and payment controls in
			force for the country in question. <br />
			<br />
			• Refunds of payments made through visa are subjected to the terms and
			conditions for refunds, as well as the conditions for each service. Terms
			and conditions of promocodes when redeeming a promotional code advertised
			by Go Care the following terms and conditions apply where you must redeem
			a promotional code as part of the offer. <br />
			<br />
			• Any promotional code is valid for a limited time only. For example:
			Gtalat offers 100 egp off any service valid on tuesdays (sometimes valid
			till wednesdays) only for minimum order value 200 egp. Go Care reserves
			the right to modify or cancel it at any time. <br />
			<br />
			• Each promotional code can be used only once, unless otherwise specified.
			<br />
			<br />
			• Each promotional code applies only to Qualifying items. Details are
			specified in the promotion. <br />
			<br />
			• The promotional code does not apply to pre-orders, unless otherwise
			specified. <br />
			<br />
			• The promotional code does not apply to low margin products. <br />
			<br />
			• The promotional code is not transferable, may not be resold or redeemed
			for cash. <br />
			<br />
			• Only one promotional code can be used each time and therefore cannot be
			combined with other offers using promotional code. <br />
			<br />
			• Limit one promotional code per customer. if you violate any of the terms
			and conditions, the promotion will be invalid, and the promotional code
			discount will not apply. Go Care reserves the right to update these terms
			and conditions without prior notice. Memberships while enjoying Go Care
			memberships & its benefits, the following terms and conditions apply.
			<br />
			<br />
			• Go Care membership system grants the client: points, gifts, discounted
			services and more! <br />
			<br />
			• Upgrading to a new membership level requires a greater number of
			bookings, ratings, and application sharing during a certain time frame.
			<br />
			<br />
			• granted points can be used only once, unless otherwise specified. <br />
			<br />
			• Redeeming points for gifts or services work only on specific services
			and gifts. Details are specified in the promotion. <br />
			<br />
			• Granted points can be redeemed for wallet money. Such that each point is
			equivalent to 0.1 egp. the price of points may be updated without prior
			notice. <br />
			<br />
			• A clients’ membership & points drops if she stopped booking through the
			application during a period between 60 to 120 days. <br />
			<br />
			• Memberships don’t count earlier bookings, unless otherwise specified.
			<br />
			<br />
			• The membership is not transferable, may not be resold or redeemed.
			<br />
			<br />
			• Limit one membership level per customer. if you violate any of the terms
			and conditions, the membership will be invalid, and the membership’s
			benefits will not apply. Go Care reserves the right to update these terms
			and conditions without prior notice. Cookies Go Care uses cookies for the
			following purposes: to enable certain functions of the service, to provide
			analytics, to store your preferences, to enable advertisements delivery,
			including behavioral advertising. by using Go Care, you hereby approve
			that the application uses cookies. important note Go Care reserves the
			right to modify these terms of use at any time without prior notice. The
			terms and conditions apply to Go Care website and its associated
			applications on iphone, ipad, android, and mobile site platforms.
		</p>
	</div>
	<div *ngIf="lang === 'ar'" class="mt-4 text-right">
		<p class="text-gray-400">
			بإستخدام هذه المنصة ، من خلال تطبيق الهاتف المحمول أو الويب ، فأنت كعميل
			ملزمون ويجب عليك الالتزام بأحكام اتفاقية المستخدم الخاصة
			<br />
			Go Care . إستخدام بياناتك الشخصية سيتم إستخدام أي بيانات شخصية تشاركها مع
			Go Care فقط لتحسين تجربة المستخدم. ستتم مشاركة بياناتك الشخصية مع شركائنا
			في التسويق. قائمة مقدمي الخدمات والأسعار الغرض من هذا التطبيق هو تقديم
			خدمة بسيطة ومريحة للمستهلكين ، وربطهم بمراكز التجميل والعيادات في منطقتهم.
			تلتزم مراكز التجميل والعيادات والعمل مع Go Care بالامتثال للقوانين
			والقواعد واللوائح المحلية ومعايير صناعة التجميل والبيع والتسويق والسلامة
			لا يتحقق تطبيق Go Care بأي شكل من الأشكال بشكل مستقل من أوراق الاعتماد أو
			الإقرارات أو منتجات مراكز التجميل والعيادات أو جودة أي منتجات. تقع على
			عاتق العميل مسؤولية أن يكون مرتاحًا للمعلومات التي تقدمها مراكز التجميل
			والعيادات في التطبيقات. نعني بالمعلومات: معلومات مقدمي الخدمة ، وتقديم
			الخدمات ، والمعلومات حول المنتجات والخدمات والعروض والحزم وما إلى ذلك ، Go
			Care لا يتحمل أي مسؤولية أو مسؤولية عن تلك المعلومات. وبالتأكيد يسعى Go
			Care لإضافة المزيد من Go Cares التجميل والعيادات الموثوقة تحضير جميع
			الخدمات هو المسؤولية الوحيدة لمركز التجميل أو العيادة التي تقبل الطلب.
			العملاء ، خارج الدورة التدريبية ، يحتفظون بالحق في الإبلاغ عن مزود أو
			تقديم شكوى أو تقييم المزود. Go Care مسؤول عن قوائم الأسعار المدرجة في
			التطبيق. ومع ذلك ، يتم تحديث القائمة بشكل متكرر. يجب على العملاء التأكد من
			أنهم يتحققون من الأسعار قبل التوجه إلى مزود الخدمة الخدمات المنزليه يقدم
			لك Go Care خدمات منزلية. يرجى العلم بأنه لا يمكن تقديم بعض الخدمات في
			المنزل. تتبع الخدمات المنزلية قواعد صارمة لضمان راحة عملائنا الكرام وكذلك
			لضمان حقوق مزودي الخدمة لدينا كما هو موضح أدناه.
			<br />
			• يتطلب حجز هذه الخدمة دفع وديعة وإلا سيتم إلغاء الحجز. <br />
			• قد يتطلب الحجز مدفوعات فوري أو فيزا (إن وجدت). بعد الحجز ، انتظر مكالمة
			هاتفية من وكلاء خدمة العملاء أو مقدم الخدمة لتطبيق Go Care لتأكيد وإبلاغك
			بتفاصيل الدفع. <br />
			• إعادة الأموال يتوافق مع شروط وأحكام كل خدمة. كما أنه يتوافق مع شروط
			وأحكام طرق الدفع. <br />
			• يجب على المستخدم أن يوضح مقدمًا أي متطلبات تتعلق بالمواد أو أي توضيحات
			أخرى في حقل الملاحظات. <br />
			• يلتزم المستخدم بإعطاء المعلومات الشخصية الصحيحة والعنوان الصحيح. <br />
			• الهدف من تقديم هذه الخدمة هو مساعدة العملاء لذلك فمن غير المناسب التسبب
			في الأذى أو المضايقة لمقدم الخدمة. <br />
			• إذا كنت ترغب في إضافة خدمة أخرى أثناء الحجز ، يرجى إستخدام التطبيق لضمان
			السعر والجودة. <br />
			• الأسعار المعروضة في التطبيق تقريبية وقد تتغير حسب شروط وأحكام الخدمة.
			<br />
			• يحق لمقدم الخدمة قبول الحجز أو رفضه بسبب العنوان والمسافة أو الانشغال أو
			لأي أسباب أخرى. <br />
			• لمقدم الخدمة الحق في تغيير المواد المستخدمة وفقًا للخدمة المطلوبة.
			<br />
			• لا يمكن إلغاء الحجوزات قبل موعد الخدمة بيوم واحد أو أثناء تقديمها. قد
			يعرضك هذا لرسوم تعويضية. <br />
			• الحفاظ على ممتلكاتك الشخصية هي مسؤوليتك وحدك.

			<br />
			<br />

			خدمة الاستشارة هذه نسخة تجريبية من خدمات الاستشارة. نحن نبذل جهودًا لتحديث
			هذه الخدمة لخدمة احتياجات مستخدمينا الكرام بشكل أفضل. نلاحظ أيضًا ما يلي:
			<br />
			<br />
			• هذه الخدمة مجانية تمامًا ولفترة محدودة. <br />
			<br />
			• تستخدم البيانات التي تملأها في نموذج الاستشارة لأغراض ترويجية ولمساعدتنا
			في تحسين الخدمة. <br />
			<br />
			• فقط خبراء التجميل والصحة هم المسؤولون عن النصائح المقدمة. <br />
			<br />
			• يحتفظ Go Care بالحق في تعديل هذه الشروط والأحكام دون إشعار. عدم الحضور
			والحجز والإلغاء إذا لم يصل العميل في الوقت المحجوز ، يحق للمزود إلغاء
			حجزه. إذا ألغى المزود الحجز بدون سبب ، يكون Go Care مسؤولاً عن هذه
			المشكلة. نحث عملائنا على الإبلاغ عن ذلك. العروض والحزم والمدفوعات كل عرض
			أو باقة تسترشد بالشروط والأحكام. طريقة الدفع الأساسية هي نقدًا. قد تتطلب
			بعض الخدمات والعروض الدفع الإلكتروني أو الائتماني بواسطة بطاقة visa card
			أو mastercard. مدفوعات فوري والمبالغ المستردة هناك عدة طرق للدفع مقابل
			الخدمة المقدمة من خلال التطبيق. وهي: الدفع النقدي ، والدفع من خلال المحفظة
			الإلكترونية ، والدفع عن طريق الائتمان أو بطاقة الفيزا ، والدفع عن طريق
			فوري.

			<br />
			<br />
			• في حالة الدفع عن طريق فوري ، يرجى الاحتفاظ بإيصال فوري كدليل على الدفع.
			<br />
			<br />
			• يمكن استرداد مدفوعاتك وفقًا للشروط والأحكام العامة للمبالغ المستردة
			ووفقًا لشروط وأحكام الخدمة أيضًا. <br />
			<br />
			• مدى توفر إمكانية استرداد الأموال يعتمد بشكل أساسي على تاريخ الحجز. في
			بعض الحالات ، لا تتوفر المبالغ المعادة. <br />
			<br />
			• تنطبق المبالغ المستردة على الحالات التالية: الحجز عن طريق الخطأ - لم
			يؤكد المزود الحجز - ألغى المزود الحجز - الخدمة غير متوفرة - ظروف العميل.
			حيث أن شروط وأحكام الدفع والاسترداد لا تنتهك أو تتعارض.

			<br />
			<br />
			• لا يمكن رد مدفوعات فوري إلا في شكلين: الأموال إلى بطاقة visa أو أموال
			المحفظة داخل التطبيق. لاحظ أن: <br />
			<br />
			• يتم تسليم المبلغ المردود إلى بطاقة visa الخاصة بك في غضون 14 يومًا
			<br />
			<br />
			• استرداد الأموال كمحفظة داخل التطبيق يتم تسليمها في غضون 24 ساعة. <br />
			<br />
			• لا يمكن إستخدام أموال المحفظة داخل التطبيق إلا لحجز الخدمات ولا يمكن
			تحويلها إلى مبلغ نقدي حقيقي. مدفوعات التأشيرة والمبالغ المستردة <br />
			<br />
			• قد يكون الدفع من خلال الفيزا متاحًا لبعض مقدمي الخدمة. <br />
			<br />
			• قد يتطلب الحجز طرق دفع أخرى لبعض الخدمات. <br />
			<br />
			• يلتزم العميل بالتأكد من أن رصيد البطاقة كافٍ لإتمام الدفع.

			<br />
			<br />
			• يجب على العميل الاحتفاظ بإيصال الدفع كدليل على الدفع. <br />
			<br />
			• قد يكون الدفع من خلال الفيزا متاحًا لبعض مقدمي الخدمة. <br />
			<br />
			• قد يتطلب الحجز طرق دفع أخرى لبعض الخدمات. <br />
			<br />
			• يلتزم العميل بالتأكد من أن رصيد البطاقة كافٍ لإتمام الدفع. <br />
			<br />
			• يجب على العميل الاحتفاظ بإيصال الدفع كدليل على الدفع. <br />
			<br />
			• يخضع الدفع عن طريق التأشيرة للقواعد القانونية وضوابط الدفع المعمول بها
			في البلد المعني. <br />
			<br />
			• تخضع المبالغ المعادة للمدفوعات التي تتم عن طريق فيزا لشروط وأحكام عمليات
			الاسترداد ، بالإضافة إلى شروط كل خدمة. شروط وأحكام الرموز الترويجية عند
			استرداد رمز ترويجي معلن عنه من قبل Go Care ، تطبق الشروط والأحكام التالية
			حيث يجب عليك استرداد رمز ترويجي كجزء من العرض. <br />
			<br />
			• أي رمز ترويجي صالح لفترة محدودة فقط. على سبيل المثال: تقدم gtalat خصمًا
			بقيمة 100 جنيه مصري على أي خدمة صالحة أيام الثلاثاء (أحيانًا صالحة حتى
			الأربعاء) فقط للحد الأدنى لقيمة الطلب 200 جنيه مصري. يحتفظ تطبيق Go Care
			بحق التعديل أو الإلغاء في أي وقت. <br />
			<br />
			• يمكن إستخدام كل رمز ترويجي مرة واحدة فقط ، ما لم يتم تحديد خلاف ذلك.
			<br />
			<br />
			• كل رمز ترويجي ينطبق فقط على العناصر المؤهلة. التفاصيل محددة في العرض
			الترويجي. <br />
			<br />
			• لا تنطبق الشفرة الترويجية على الطلبات المسبقة ، ما لم يتم تحديد خلاف
			ذلك. <br />
			<br />
			• لا تنطبق التعليمات البرمجية الترويجية على المنتجات ذات الهامش المنخفض.
			<br />
			<br />
			• الشفرة الترويجية غير قابلة للتحويل ، ولا يجوز إعادة بيعها أو استردادها
			نقدًا. <br />
			<br />
			• يمكن إستخدام رمز ترويجي واحد فقط في كل مرة ، وبالتالي لا يمكن دمجه مع
			عروض أخرى بإستخدام الرمز الترويجي. <br />
			<br />
			• تحديد رمز ترويجي واحد لكل عميل.

			<br /><br />
			إذا انتهكت أيًا من الشروط والأحكام ، فسيكون العرض الترويجي غير صالح ، ولن
			يتم تطبيق خصم الرمز الترويجي. يحتفظ Go Care بالحق في تحديث هذه الشروط
			والأحكام دون إشعار مسبق. العضويات أثناء الاستمتاع بعضوية Go Care وفوائده ،
			تطبق الشروط والأحكام التالية.

			<br />
			<br />
			• يمنح نظام عضوية Go Care العميل: نقاط ، هدايا ، خدمات مخفضة والمزيد!
			<br />
			<br />
			• يتطلب الترقية إلى مستوى عضوية جديد عددًا أكبر من الحجوزات والتقييمات
			ومشاركة الطلبات خلال فترة زمنية معينة. <br />
			<br />
			• يمكن إستخدام النقاط الممنوحة مرة واحدة فقط ، ما لم يتم تحديد خلاف ذلك.
			<br />
			<br />
			• استبدال النقاط مقابل الهدايا أو الخدمات يعمل فقط على خدمات وهدايا معينة.
			التفاصيل محددة في العرض الترويجي. <br />
			<br />
			• يمكن استبدال النقاط الممنوحة بأموال المحفظة. بحيث تعادل كل نقطة 0.1 جنيه
			مصري. يمكن تحديث سعر النقاط دون إشعار مسبق. <br />
			<br />
			• تنخفض عضوية العميل ونقاطه إذا توقفت عن الحجز من خلال التطبيق خلال فترة
			تتراوح بين 60 إلى 120 يومًا. <br />
			<br />
			• لا تحسب العضويات الحجوزات السابقة ، ما لم يتم تحديد خلاف ذلك. <br />
			<br />
			• العضوية غير قابلة للتحويل ، ولا يجوز إعادة بيعها أو استردادها. <br />
			<br />
			• تحديد مستوى عضوية واحد لكل عميل. إذا انتهكت أيًا من الشروط والأحكام ،
			فستكون العضوية غير صالحة ، ولن يتم تطبيق مزايا العضوية. يحتفظ Go Care
			بالحق في تحديث هذه الشروط والأحكام دون إشعار مسبق. كوكيز يستخدم salon
			ملفات تعريف الارتباط للأغراض التالية: لتمكين وظائف معينة للخدمة ، ولتوفير
			التحليلات ، ولتخزين تفضيلاتك ، ولتمكين تسليم الإعلانات ، بما في ذلك
			الإعلانات السلوكية. بإستخدام Go Care ، فإنك توافق على أن التطبيق يستخدم
			ملفات تعريف الارتباط. ملاحظة مهمة يحتفظ Go Care بالحق في تعديل شروط
			الإستخدام هذه في أي وقت دون إشعار مسبق. تطبق الشروط والأحكام على موقع Go
			Care والتطبيقات المرتبطة به على منصات iphone و ipad و android و mobile
			site platform.
		</p>
	</div>
	<div class="flex">
		<button
			(click)="confirm()"
			class="bg-gradient text-white w-full mt-3"
			mat-button
		>
			{{ 'ACTIONS.GOT_IT' | translate }}
		</button>
	</div>
</form>
