import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'customCurrency' })
export class CustomCurrencyPipe implements PipeTransform {
	transform(value?: number, countryId?: string, currentLang?: string) {
		if (!countryId) return ''
		if (currentLang === 'en' && countryId === '224') return value + ' EGP'
		else if (currentLang === 'en' && countryId === '103') return value + ' SAR'
		else if (currentLang === 'ar' && countryId === '224') return value + ' ج.م'
		else return value + ' ر.س'
	}
}
