<mat-select
	*ngIf="!isHeader"
	[formControl]="lang"
	(selectionChange)="langChanges()"
	class="w-50 mr-3 rtl:ml-3 rtl:mr-0"
>
	<mat-option *ngFor="let language of Languages" [value]="language.value">
		<!-- <img class="inline mr-2" [src]="language.flag" alt="icon" /> -->
		{{ language.name }}
	</mat-option>
</mat-select>
<mat-select
	*ngIf="isHeader"
	[formControl]="lang"
	(selectionChange)="langChanges()"
	class="mr-3 rtl:ml-3 rtl:mr-0 border border-solid rounded border-secondry-color-Gray lg:px-2 lg:py-3 px-0.5 py-1 header-lang"
>
	<mat-option *ngFor="let language of Languages" [value]="language.value">
		{{ language.value.toUpperCase() }}
	</mat-option>
</mat-select>
