import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-custom-stepper',
  templateUrl: './custom-stepper.component.html',
  styleUrls: ['./custom-stepper.component.scss']
})
export class CustomStepperComponent implements OnInit {
  @Input() steps : string[] = []
  @Input() actionTap : number = 1
  @Output() tapChanged : EventEmitter<number> = new EventEmitter()
  constructor() { }

  ngOnInit(): void {
  }

}
