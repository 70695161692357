import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core'

@Component({
	selector: 'app-fixed-button',
	templateUrl: './fixed-button.component.html',
	styleUrls: ['./fixed-button.component.scss'],
})
export class FixedButtonComponent implements OnInit {
	@Input() buttonTitle!: string
	@Input() buttonWidth: number = 188
	@Input() imgPath!: string
	@Input() button2Title!: string
	@Input() button2Width: number = 188
	@Input() img2Path!: string
	@Input() disabled!: boolean
	@Output() clickEvent: EventEmitter<void> = new EventEmitter()
	@Output() click2Event: EventEmitter<void> = new EventEmitter()

	isScrolling: boolean = false
	lastScrollPosition: number = 0
	isScrollingTop: boolean = false
	constructor() {}

	ngOnInit(): void {}

	@HostListener('window:scroll', ['$event'])
	onWindowScroll($event: any) {
		if (window.scrollY > this.lastScrollPosition) {
			this.isScrollingTop = false
		} else {
			this.isScrollingTop = true
		}
		this.lastScrollPosition = window.scrollY
		this.isScrolling = true
	}
}
