<div [formGroup]="mobilesForm">
	<div formArrayName="mobileNumbers" class="mb-3">
		<div
			*ngFor="let mobile of getFormArray().controls; let i = index"
			[formGroupName]="i"
		>
			<div class="costom_phone flex relative">
				<mat-form-field
					[dir]="translate.currentLang == 'ar' ? 'rtl' : 'ltr'"
					class="w-auto mr-3 rtl:ml-3 rtl:mr-0"
					appearance="outline"
				>
					<!-- 	<mat-select
						class="w-auto rtl:ml-3 ltr:mr-3"
						[ngClass]="!isEditMode ? 'text-[#6E7191]' : 'text-[#4E4B66]'"
						formControlName="country"
						(selectionChange)="checkPhoneValidator(i, $event.value)"
						[compareWith]="compareWith"
						required
					>
						<mat-option class="text-xs" value="" disabled
							>{{ 'GENERAL_LABELS.SELECT_CODE' | translate }}*</mat-option
						>
						<mat-option
							*ngFor="let country of countriesCodes"
							[value]="country"
						>
							<img
								class="inline mr-2 w-[20px]"
								[src]="attachmentUrl + country.flagUrl"
								alt="icon"
							/>
							{{ country.phoneCode }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="getController(i, 'mobileNumber')?.valid">
						<validation-message
							[controller]="getController(i, 'country')"
							controller_name="GENERAL_LABELS.PHONE_CODE"
						>
						</validation-message>
					</mat-error>-->
					<input disabled type="text" matInput [value]="country?.phoneCode" />
				</mat-form-field>
				<mat-form-field
					[dir]="translate.currentLang == 'ar' ? 'rtl' : 'ltr'"
					class="w-full  {{
						validateNumbers?.length && !validateNumbers[i]?.isUnique
							? 'ng-invalid mat-form-field-invalid'
							: ''
					}}"
					appearance="outline"
				>
					<mat-label>{{ getMobilePlaceHolder(i) | translate }}</mat-label>
					<input
						#phoneInput
						formControlName="mobileNumber"
						type="text"
						matInput
						[maxlength]="10"
						[ngClass]="!isEditMode ? 'text-[#6E7191]' : 'text-[#4E4B66]'"
						placeholder="{{ 'GENERAL_LABELS.MOBILE_NUMBER' | translate }}"
					/>
					<img
						*ngIf="
							getController(i, 'mobileNumber')?.value?.length && isEditMode
						"
						(click)="getController(i, 'mobileNumber')?.setValue('')"
						class="cursor-pointer absolute ltr:right-0 rtl:left-0 inline-block top-[9px]"
						src="./assets/images/close-square.svg"
						alt=""
					/>

					<button
						matSuffix
						mat-icon-button
						*ngIf="i > 0 && isEditMode"
						(click)="removeMobile(i)"
						class="w-7 h-6 top-4 rtl:mr-2 ltr:ml-2"
					>
						<img class="" src="assets/images/delete.svg" alt="" />
					</button>
					<mat-error>
						<validation-message
							[controller]="getController(i, 'mobileNumber')"
							controller_name="GENERAL_LABELS.MOBILE_NUMBER"
							pattern_Message="VALIDATION.PHONE"
						>
						</validation-message>
					</mat-error>
				</mat-form-field>
			</div>

			<mat-error>
				<small
					class="mb-3 block"
					style="margin-top: -2rem"
					*ngIf="validateNumbers?.length && !validateNumbers[i]?.isUnique"
					>{{ validateNumbers[i]?.uniqueMobileNumberErrorMessage }}</small
				>
			</mat-error>
		</div>

		<small
			*ngIf="isDuplicated"
			class="whitespace-nowrap text-red-error text-font-12"
			>{{ 'VALIDATION.MOBILE_DUPLICATED' | translate }}</small
		>
	</div>
	<div
		*ngIf="getFormArray().length < 3 && isEditMode"
		class="rtl:text-left ltr:text-right cursor-pointer mb-2"
	>
		<span class="text-font-14 text-link" (click)="addNewMobile()">
			+ {{ 'ACTIONS.ADD_ANOTHER_MOBILE' | translate }}
		</span>
	</div>
</div>
