import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, map } from 'rxjs'
import { ApiEndPoint } from '../constants/apis.constant'
import { Countries } from '../models/general.models'

@Injectable({
	providedIn: 'root',
})
export class SharedService {
	headerTitle = new BehaviorSubject('')
	breadCrumbRout: any = new BehaviorSubject('')
	constructor(private http: HttpClient) {}

	getServiceProviderTypes() {
		return this.http.get(`${ApiEndPoint.getServiceProviderTypesApi}`)
	}

	getCountryCode() {
		return this.http.get(`${ApiEndPoint.getCountryCodeApi}?IsActive=true`)
	}
	getActiveCountries() {
		return this.http.get(`${ApiEndPoint.getCountryCodeApi}?IsActive=true`).pipe(
			map((response: any) => {
				return response.result as Countries
			})
		)
	}

	returnEnumArray(enumData: any) {
		let newArray: any[] = []
		Object.keys(enumData).forEach((value: string) =>
			isNaN(+value)
				? newArray.push({
						name: value,
						value: enumData[value as any],
				  })
				: null
		)
		return newArray
	}

	handleDate(date: Date) {
		var newDate = new Date(date)
		return new Date(
			newDate.getTime() + Math.abs(newDate.getTimezoneOffset() * 60000)
		).toISOString()
	}

	handleBreadCrumb(
		name: string,
		resetBreadcrumb: boolean,
		body: {
			name?: string
			id?: string | number | null
			url: string
			queryParams?: any
		}
	) {
		let breadCrumbList: any = {}
		if (!resetBreadcrumb) {
			let rout = this.breadCrumbRout.subscribe((res: any) => {
				breadCrumbList = res
			})
			rout.unsubscribe()
		}
		breadCrumbList[name] = { ...body }
		this.breadCrumbRout.next(breadCrumbList)
	}
}
