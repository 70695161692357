<div class="bg-off-white">
	<div class="mx-24px">
		<div>
			<p
				class="text-dark-grey text-font-18 ltr:font-Poppins-medium rtl:font-medium leading-[22px]"
			>
				{{ 'LABELS.FILTRATION' | translate }}
			</p>
			<app-custom-datepicker></app-custom-datepicker>
		</div>
		<div class="w-100 h-[1px] bg-secondry-color-Gray my-24px"></div>
		<div>
			<p
				class="text-dark-grey text-font-18 ltr:font-Poppins-medium rtl:font-medium leading-[22px]"
			>
				Services List
			</p>

			<mat-form-field appearance="fill">
				<mat-label>Service name</mat-label>
				<mat-select>
					<mat-option value="one">First option</mat-option>
					<mat-option value="two">Second option</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<div class="w-100 h-[1px] bg-secondry-color-Gray my-24px"></div>

		<div>
			<p
				class="text-dark-grey text-font-18 ltr:font-Poppins-medium rtl:font-medium leading-[22px]"
			>
				Appointment Status
			</p>
			<div class="mb-[22px] ml-[11px]">
				<mat-checkbox></mat-checkbox>
				<span class="text-black ml-[11px]">Completed</span>
			</div>
			<div class="mb-[22px] ml-[11px]">
				<mat-checkbox></mat-checkbox>
				<span class="text-black ml-[11px]">Rejected</span>
			</div>
			<div class="mb-[22px] ml-[11px]">
				<mat-checkbox></mat-checkbox>
				<span class="text-black ml-[11px]">Canceled</span>
			</div>
		</div>
		<div>
			<p
				class="text-dark-grey text-font-18 ltr:font-Poppins-medium rtl:font-medium leading-[22px]"
			>
				Payment Status
			</p>
			<div class="mb-[22px] ml-[11px]">
				<mat-checkbox></mat-checkbox>
				<span class="text-black mx-[11px]">Paid</span>
			</div>
			<div class="mb-[22px] ml-[11px]">
				<mat-checkbox></mat-checkbox>
				<span class="text-black mx-[11px]">Un Paid</span>
			</div>
			<div class="mb-[22px] ml-[11px]">
				<mat-checkbox></mat-checkbox>
				<span class="text-black ml-[11px]">Deposit Paid</span>
			</div>
		</div>
		<div class="mt-[87px] mb-[23px]">
			<button class="bg-gradient text-white w-full rounded-[6px] py-[10px]">
				Apply Filter
			</button>
		</div>
	</div>
</div>
