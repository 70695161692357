<span
	*ngIf="yellowStatus.includes(statusName?.toUpperCase() || '')"
	class="whitespace-nowrap text-[#F58608] bg-[#FFFAE4] text-font-[14px] spanst-disc text-center rounded-[50px] inline-block py-1 px-[6px]"
>
	<span
		class="w-[5px] h-[5px] bg-[#F58608] rounded inline-block align-middle"
	></span>
	{{ 'STATUS.' + statusName | translate }}
	<span *ngIf="ExtraText">/ {{ ExtraText }}</span>
</span>
<span
	*ngIf="greenStatus.includes(statusName?.toUpperCase() || '')"
	class="whitespace-nowrap text-[#00BA88] bg-[#E2F5EE] text-font-[14px] spanst-disc text-center rounded-[50px] inline-block py-1 px-[6px]"
>
	<span
		class="w-[5px] h-[5px] bg-[#00BA88] rounded inline-block align-middle"
	></span>

	{{ 'STATUS.' + statusName | translate }}
	<span *ngIf="ExtraText">/ {{ ExtraText }}</span>
</span>
<span
	*ngIf="redStatus.includes(statusName?.toUpperCase() || '')"
	class="whitespace-nowrap text-[#B3261E] gradient text-font-[14px] spanst-disc text-center rounded-[50px] inline-block py-[2px] px-[6px]"
>
	<span
		class="w-[5px] h-[5px] bg-[#B3261E] rounded inline-block align-middle"
	></span>
	{{ 'STATUS.' + statusName | translate }}
	<span *ngIf="ExtraText">/ {{ ExtraText }}</span>
</span>
<span
	*ngIf="salonStatus.includes(statusName?.toUpperCase() || '')"
	class="whitespace-nowrap text-gradient text-font-[14px] spanst-disc text-center rounded-[50px] inline-block py-1 px-[6px]"
>
	<span
		class="w-[5px] h-[5px] bg-gradient rounded inline-block align-middle"
	></span>
	{{ 'STATUS.' + statusName | translate }}
	<span *ngIf="ExtraText">/ {{ ExtraText }}</span>
</span>
