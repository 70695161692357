<div [ngClass]="flag ? 'minimizcalendar' : ''" class="calendar">
	<div class="relative">
		<a (click)="flag = !flag" class="cursor-pointer icon">
			<mat-icon fontSet="material-icons-outlined">zoom_out_map</mat-icon>
		</a>
		<mat-calendar
			[minDate]="flag ? minDate : null"
			[maxDate]="flag ? maxDate : null"
			[(selected)]="selected"
		></mat-calendar>
	</div>
</div>
