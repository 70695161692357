export enum BranchTypesEnum {
	FEMALE,
	MALE

}
export const BranchTypes: { name: any; value: any; icon: string }[] = []
Object.values(BranchTypesEnum).forEach((value: string | number) =>
	isNaN(+value)
		? BranchTypes.push({
			name: value,
			value: BranchTypesEnum[value as any],
			icon: `/assets/images/${value.toString().toLocaleLowerCase()}.svg`,
		})
		: null
)

export enum WeekDaysEnum {
	Sunday,
	Monday,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
	Saturday,

}

export const weekDays: { name: string; value: string }[] = []
Object.keys(WeekDaysEnum).forEach((value: string) =>
	isNaN(+value)
		? weekDays.push({ name: value, value: WeekDaysEnum[value as any] })
		: null
)
