import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './core/guard/auth.guard'

const routes: Routes = [
	{
		path: '',
		redirectTo: 'layout',
		pathMatch: 'full',
	},
	{
		path: 'layout',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./core/layout/layout.module').then((m) => m.LayoutModule),
	},
	{
		path: 'auth',
		loadChildren: () =>
			import('./modules/auth/auth.module').then((m) => m.AuthModule),
	},
]

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
