import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Table } from '../../models/general.models'
import { TranslateService } from '@ngx-translate/core'
import { environment } from '../../../../environments/environment'

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
	@Input() tableConfig: Table = new Table()
	@Input() noDataFoundMessage = 'GENERAL.NO_DATA_FOUND'
	@Output() emitAction: EventEmitter<any> = new EventEmitter()
	@Output() getNextPage: EventEmitter<any> = new EventEmitter()
	imagePath = environment.attachmentPath
	constructor(public translate: TranslateService) { }

	ngOnInit(): void { }

}
