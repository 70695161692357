<div class="inline-flex border-b-[1px] border-secondry-color-Gray tabs">
	<div *ngFor="let tap of taps; let i = index" class="relative {{
			translate.currentLang == 'ar' ? 'tabi-ar' : 'tabi-en'
		}} ">
		<div *ngIf="i !== 0"
			class="h-[6px] w-[6px] ltr:mr-[18px] rtl:ml-[18px] bg-input-background rounded-full absolute top-[50%]">
		</div>

		<div [style]="'padding:  8px ' + padding + 'px'" class="flex items-center mr-18px  ltr:Poppins-regular rtl:font-Arabic-regular cursor-pointer  max-mobile:text-font-mobile-small max-mobile:px-padding-mobile-14 whitespace-nowrap {{
				i == 0 ? 'ltr:pl-[0px] rtl:pr-[0px]' : ''
			}}  {{ tap?.customText }} {{
				activeTap == tap?.value || activeTap == tap
					? tap?.customClass?.length
						? tap?.customClass
						: 'active-tab'
					: ' text-font-14 text-medium-gray'
			}} {{ translate.currentLang == 'ar' ? 'tab-ar' : 'tab-en' }}">
			<h3 class="m-0" (click)="
					$event.stopPropagation();
					activeTap = tap?.value !== undefined ? tap?.value : tap;
					tapChanged.emit(tap?.value !== undefined ? tap?.value : tap)
				">
				{{
				prefix + (tap?.name ? tap?.name.toUpperCase() : tap.toUpperCase())
				| translate
				}}
			</h3>

			<span *ngIf="tap?.showCount"
				class="ltr:ml-2 rtl:mr-2 max-mobile:right-0 w-[22px] h-[22px] bg-gradient rounded-full flex justify-center">
				<span class="text-off-white leading-7">{{ tap?.count }}</span>
			</span>
		</div>
	</div>
</div>