import { Component, Input, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'

@Component({
	selector: 'validation-message',
	templateUrl: './validation-message.component.html',
})
export class ValidationMessageComponent implements OnInit {
	@Input() controller: any = new FormControl()
	@Input() controller_name: string = ''
	@Input() pattern_Message: string = ''
	@Input() isNumber: boolean = false
	constructor() {}

	ngOnInit(): void {
	}
}
