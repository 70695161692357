import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
	selector: 'app-pricing-slider',
	templateUrl: './pricing-slider.component.html',
	styleUrls: ['./pricing-slider.component.scss'],
})
export class PricingSliderComponent implements OnInit {
	@Input() minVal = 0
	@Input() maxVal = 1000
	@Input() title!: string
	@Output() emitValue: EventEmitter<any> = new EventEmitter()
	@Input() from!: any
	@Input() to!: any
	constructor() {}

	ngOnInit(): void {}

	rangeChanged() {
		if (this.from > this.to) this.from = this.to - 100
		this.emitValue.emit({ min: this.from, max: this.to })
	}
}
